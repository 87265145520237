import { getElementTop } from "@/utils/utils";

interface scrolArgs {
	ele: HTMLElement | null;
	time?: number; // 滚动时间 毫秒  数值越大，滚动速度越慢
	diff?: number; // 距离目标元素偏移值，为正数时，目标元素距离视口有一段距离；为负数时，超过视口
	callback?: any; // 滚动结束后的回调函数
}

const useScrollToElement = () => (payload: scrolArgs) => {
	let requestAnimationId = 0;
	cancelAnimationFrame(requestAnimationId);

	const { ele, time = 400, diff = 0, callback } = payload;

	if (!ele) {
		console.error(`Initialize failed: Invalid Elementm.`);
		return;
	}

	const targetOffset = getElementTop(ele) - diff;
	// const time = 300; // 滚动时间，单位：毫秒
	const fps = 500; // 每秒帧数
	const frametime = 1000 / fps;

	const startTimestamp = ref<DOMHighResTimeStamp | null>(null);
	const originalScrollTop = window.scrollY;

	function animateScroll(timestamp: DOMHighResTimeStamp) {
		if (!startTimestamp.value) {
			startTimestamp.value = timestamp;
		}

		const elapsed = timestamp - startTimestamp.value;
		const progress = Math.min(elapsed / time, 1);
		const ease = (progress: number) => (progress < 0.5 ? 2 * progress * progress : -1 + (4 - 2 * progress) * progress);

		window.scrollTo(0, originalScrollTop + ease(progress) * (targetOffset - originalScrollTop));

		if (elapsed < time) {
			requestAnimationId = requestAnimationFrame(animateScroll);
		} else {
			if (callback) {
				callback();
			}
		}
	}

	function handleWheel() {
		// 在鼠标滚动时取消动画
		cancelAnimationFrame(requestAnimationId);
	}

	window.addEventListener("wheel", handleWheel, { passive: true });

	requestAnimationFrame(animateScroll);
};

export default useScrollToElement;
